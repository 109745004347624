import Box from '@mui/material/Box';
import { selectPmgTag } from '../../../redux/bRTags';
import { useAppSelector } from '../../../redux/hooks';
import { AgentResult } from '../../../types';
import ResultItem from './ResultItem';

export default function Cluster({ results }: { results: AgentResult[] }) {
  const pmgTag = useAppSelector(selectPmgTag);
  const currentTagId = useAppSelector((state) => state.selectedTagId);

  function resultOrder(a: AgentResult, b: AgentResult) {
    const aHasPmg = pmgTag && a.tag_ids.includes(pmgTag.id);
    const bHasPmg = pmgTag && b.tag_ids.includes(pmgTag.id);
    if (aHasPmg && !bHasPmg) {
      return -1;
    }
    if (!aHasPmg && bHasPmg) {
      return 1;
    }
    return b.relevance_scores[currentTagId] - a.relevance_scores[currentTagId];
  }

  const color = results.length > 1 ? '#1976d2' : 'transparent';

  return (
    <Box
      sx={{
        textAlign: 'left',
        borderLeft: `3px solid ${color}`,
        '& .MuiCard-root:not(:last-child)': {
          marginBottom: '0',
          borderBottomLeftRadius: '0',
          borderBottomRightRadius: '0',
        },
        '& .MuiCard-root:not(:first-of-type)': {
          borderTopLeftRadius: '0',
          borderTopRightRadius: '0',
          borderTop: '0',
        },
      }}
    >
      {results.sort(resultOrder).map((r) => (
        <ResultItem key={r.agent_result_id} agentResult={r} />
      ))}
    </Box>
  );
}
