import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';
import { AxiosError, CancelTokenSource } from 'axios';
import { EnrichedResultsQuery, getAgentResults, serializeError } from '../api';
import { ApiError, AgentResult } from '../types';
import { selectPmgTag } from './bRTags';
import { RootState } from './store';

export interface AgentResultsState {
  data: AgentResult[] | null;
  error: ApiError | null;
  isLoading: boolean;
}

const initialState: AgentResultsState = {
  data: null,
  error: null,
  isLoading: false,
};

// thunk
export const fetchAgentResults = createAsyncThunk<
  AgentResult[],
  { body: EnrichedResultsQuery; cancelToken?: CancelTokenSource },
  { rejectValue: AgentResultsState }
>(
  'agentResults/fetch',
  async function ({ body, cancelToken }, { signal, rejectWithValue }) {
    try {
      signal.addEventListener('abort', () => cancelToken?.cancel());
      const resp = await getAgentResults(body, cancelToken);
      return resp.data.collection;
    } catch (err) {
      return rejectWithValue({
        ...initialState,
        error: serializeError(err as AxiosError),
      });
    }
  }
);

interface ToggleTagPayload {
  resultId: number;
  tagId: number;
}

// slice
export const {
  reducer,
  actions: { toggleTag },
} = createSlice({
  name: 'agentResults',
  initialState,
  reducers: {
    // adds or removes a given tag ID to/from tag_ids of a given result
    toggleTag(state, action: PayloadAction<ToggleTagPayload>) {
      if (state.data) {
        const { resultId, tagId } = action.payload;
        const index = state.data.findIndex(
          (r) => r.agent_result_id === resultId
        );
        return {
          ...state,
          data: [
            ...state.data.slice(0, index),
            {
              ...state.data[index],
              tag_ids: state.data[index].tag_ids.includes(tagId)
                ? [...state.data[index].tag_ids.filter((id) => id !== tagId)]
                : [...state.data[index].tag_ids, tagId],
            },
            ...state.data.slice(index + 1),
          ],
        };
      }
      return state;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchAgentResults.fulfilled, (_, action) => ({
      ...initialState,
      data: action.payload,
    }));
    builder.addCase(fetchAgentResults.pending, () => ({
      ...initialState,
      isLoading: true,
    }));
    builder.addCase(fetchAgentResults.rejected, (_, action) => action.payload!);
  },
});

export default reducer;

// selectors

export interface Cluster {
  id: number;
  tag: number;
  maxScore: number;
  hasPmg: boolean;
  results: AgentResult[];
}

export const selectClusters = createSelector(
  (state: RootState) => state.agentResults.data,
  selectPmgTag,
  (results, pmgTag) => {
    const clusters: { [id: number]: Cluster } = {};

    results?.forEach((result) => {
      const { cluster_id: cid } = result;
      if (!clusters[cid]) {
        clusters[cid] = {
          id: cid,
          tag: 0,
          maxScore: 0,
          hasPmg: false,
          results: [],
        };
      }
      if (pmgTag?.id && result.tag_ids.includes(pmgTag?.id)) {
        clusters[cid].hasPmg = true;
      }
      // find tag with highest relevance for this result
      const [tagId, maxScore] = Object.entries(result.relevance_scores).sort(
        (a, b) => b[1] - a[1]
      )[0];
      if (maxScore > clusters[cid].maxScore) {
        clusters[cid].maxScore = maxScore;
        clusters[cid].tag = parseInt(tagId);
      }

      clusters[cid].results.push(result);
    });

    return Object.values(clusters).sort((a, b) => {
      if (a.hasPmg && !b.hasPmg) {
        return -1;
      }
      if (!a.hasPmg && b.hasPmg) {
        return 1;
      }
      if (a.maxScore > b.maxScore) {
        return -1;
      }
      if (a.maxScore < b.maxScore) {
        return 1;
      }
      return 0;
    });
  }
);

export const selectClustersForCurrentTag = createSelector(
  selectClusters,
  (state: RootState) => state.selectedTagId,
  (clusters, tagId) => clusters.filter((c) => c.tag === tagId)
);
